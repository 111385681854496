<template>
  <v-row justify="center">
    <v-col cols="5" class="mb-5">
      <!-- Header -->
      <h2 class="pt-5 text-center">Project Dashboard</h2>
      <!-- Loading -->
      <v-row v-if="loading" class="pt-5" justify="center">
        <v-icon>mdi mdi-loading mdi-spin</v-icon>
      </v-row>
      <!-- Error -->
      <v-row v-if="error" class="pt-5 red--text" justify="center">
        {{ error }}
      </v-row>
      <!-- Controls -->
      <v-row no-gutters class="pt-5" align="center" style="flex-wrap: nowrap;">
        <v-col cols="3" class="pr-2">
          <v-select
            v-model="sortBy"
            :items="['Name', 'Date (Newest First)', 'Date (Oldest First)']"
            @change="sortProjects"
            label="Sort By"
            :disabled="loading"
            dense
            filled
            hide-details
          />
        </v-col>
        <v-col cols="3" class="pl-2 pr-2">
          <v-autocomplete
            v-if="userData && userData.isAdmin"
            v-model="viewAsUser"
            :items="['admin', ...users]"
            item-text="personName"
            item-value="idPerson"
            @change="setViewAsUser"
            label="View As User"
            :disabled="loading"
            dense
            filled
            clearable
            hide-details
            return-object
          />
        </v-col>
        <v-col cols="3" class="pl-2 pr-2">
          <v-autocomplete
            v-if="userData && userData.isAdmin"
            v-model="viewAsCompany"
            :items="companies"
            item-text="name"
            @change="setViewAsCompany"
            label="View As Company"
            :disabled="loading"
            dense
            filled
            clearable
            hide-details
            return-object
          />
        </v-col>
        <v-col cols="3" align="right">
          <v-btn
            v-if="userData && userData.isAdmin"
            to="/project"
            class="white--text"
            color="primaryButton"
          >
            + New Project
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-5" align="center" style="flex-wrap: nowrap;">
        <v-col cols="12">
          <v-text-field
            v-model="search"
            @input="searchProjects"
            label="Search Projects by Name"
            :disabled="loading"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- Thumbnails -->
      <router-link v-for="(project, i) in filteredProjects" :key="i" :to="{ path: 'project', query: { id: project.idProject } }" class="black--text text-decoration-none">
        <ProjectThumbnail :projectData="project" :key="componentKey" class="mt-5"></ProjectThumbnail>
      </router-link>
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ProjectThumbnail from "@/components/ProjectThumbnail"

  export default {
    name: 'Projects',

    components: {
      ProjectThumbnail
    },

    data () {
      return {
        companies: [],
        componentKey: 0,
        error: '',
        filteredProjects: [],
        loading: false,
        projects: [],
        search: '',
        sortBy: 'Date (Newest First)',
        users: [],
        viewAsCompany: null,
        viewAsUser: null
      }
    },

    computed: {
      ...mapGetters([
        'userData'
      ])
    },

    created () {
      if (this.userData.isAdmin) {
        this.getCompanies()
        this.getUsers()
      }

      this.getProjects()
    },

    methods: {
      getCompanies () {
        this.loading = true
        this.$store.dispatch('getCompanies')
        .then((resp) => {
          if (resp.errors) {
            this.error = resp.errors
          } else {
            this.companies = resp.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
          }
          this.loading = this.users.length == 0
        })
        .catch((error) => {
          this.error = error
          this.loading = false
          if (this.error.code == 'permission-denied') {
            this.$store.dispatch('logout')
            .then(() => {
              this.$router.push('/login')
            })
          }
        })
      },

      getProjects () {
        this.projects = []
        this.loading = true
        this.$forceUpdate()

        // If view-as is selected, get projects based on the selected ID
        if (((this.viewAsUser != null && this.viewAsUser.roles && this.viewAsUser.roles.indexOf("Admin") == -1) || this.viewAsCompany != null) && this.viewAsUser !== 'admin') {
          var viewAsDispatch
          var viewAsID
          if (this.viewAsUser != null) {
            viewAsDispatch = 'getProjectsByUserID'
            viewAsID = this.viewAsUser.idPerson
          } else if (this.viewAsCompany != null) {
            viewAsDispatch = 'getProjectsByCompanyID'
            viewAsID = this.viewAsCompany.idCompany
          }

          this.$store.dispatch(viewAsDispatch, viewAsID)
          .then((resp) => {
            if (resp.errors) {
              this.error = resp.errors
            } else {
              this.projects = resp
              this.searchProjects()
            }
            this.loading = false
          })
          .catch((error) => {
            this.error = error
            this.loading = false
            if (this.error.code == 'permission-denied') {
              this.$store.dispatch('logout')
              .then(() => {
                this.$router.push('/login')
              })
            }
          })
        // Otherwise, get all projects for this user's ID (backend will return all projects if this user is admin)
        } else {
          this.$store.dispatch('getProjects')
          .then((resp) => {
            if (resp.errors) {
              this.error = resp.errors
            } else {
              this.projects = resp
              this.searchProjects()
            }
            this.loading = false
          })
          .catch((error) => {
            this.error = error
            this.loading = false
            if (this.error.code == 'permission-denied') {
              this.$store.dispatch('logout')
              .then(() => {
                this.$router.push('/login')
              })
            }
          })
        }
      },

      getUsers () {
        this.loading = true
        this.$store.dispatch('getUsers')
        .then((resp) => {
          if (resp.errors) {
            this.error = resp.errors
          } else {
            this.users = resp.sort((a, b) => a.personName.toLowerCase() > b.personName.toLowerCase() ? 1 : -1)
          }
          this.loading = this.companies.length == 0
        })
        .catch((error) => {
          this.error = error
          this.loading = false
        })
      },

      searchProjects () {
        this.filteredProjects = this.projects.filter(proj => 
          proj.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        )
        this.componentKey++;
      },

      sortProjects () {
        if (this.sortBy == 'Name') {
          this.projects = this.projects.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
        } else if (this.sortBy == 'Date (Newest First)') {
          this.projects = this.projects.sort((a, b) => a.startDateTime > b.startDateTime ? -1 : 1)
        } else if (this.sortBy == 'Date (Oldest First)') {
          this.projects = this.projects.sort((a, b) => a.startDateTime > b.startDateTime ? 1 : -1)
        }
        this.componentKey++; // required in order to force refresh the thumbnails, otherwise the logos will appear in the wrong thumbnails
        this.searchProjects()
        this.$forceUpdate()
      },

      setViewAsCompany () {
        this.viewAsUser = null
        this.getProjects()
      },

      setViewAsUser () {
        this.viewAsCompany = null
        this.getProjects()
      }
    }
  }
</script>